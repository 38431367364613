import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
// import "~/channels"
import 'jquery';
import '@captaincodeman/transition'
// import "flag-icon-css/css/flag-icon.min.css"

Rails.start()
ActiveStorage.start()

import "~/controllers"
